<template>
  <b-overlay :show="formShow" rounded="sm" no-fade class="mt-1">
    <b-card>
      <b-row class="mb-1">
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col" v-if="userId > 0">User Edit</th>
              <th scope="col" v-else>User Add</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <validation-observer ref="userRules">
        <b-form>
          <b-row>
            <!-- Name -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Name" label-for="name">
                <validation-provider name="Name" #default="{ errors }" rules="required">
                  <b-form-input id="name" type="text" v-model="userValue.name"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Surname -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Surname" label-for="surname">
                <validation-provider name="Surname" #default="{ errors }" rules="required">
                  <b-form-input id="surname" type="text" v-model="userValue.surname"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Email -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Email" label-for="email">
                <validation-provider name="Email" #default="{ errors }" rules="required|email">
                  <b-form-input id="email" type="text" :disabled="userId > 0" v-model="userValue.email"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Password -->
            <b-col cols="12" sm="6" md="4" xl="3" v-if="userValue.id == null">
              <b-form-group label="Password" label-for="password">
                <validation-provider name="Password" #default="{ errors }" rules="required">
                  <b-form-input id="password" type="text" v-model="userValue.password"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3" v-else>
              <b-form-group label="Password" label-for="password">
                <b-form-input id="password" type="text" v-model="userValue.password"></b-form-input>
              </b-form-group>
            </b-col>

            <!-- Field: Role -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="User Role" label-for="role">
                <validation-provider name="role" #default="{ errors }" rules="required">
                  <v-select v-model="userValue.role" :options="roleOptions" :reduce="(val) => val.name" label="name" :clearable="false" input-id="role" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: Status -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Status" label-for="status">
                <validation-provider name="status" #default="{ errors }" rules="required">
                  <v-select v-model="userValue.status" :options="statusOptions" :reduce="(val) => val.value" :clearable="false" input-id="status" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button v-if="userId == null" type="submit" @click.prevent="formSubmitted" variant="primary" class="float-right">
                Save
              </b-button>
              <b-button v-else type="submit" @click.prevent="formSubmitted" variant="primary" class="float-right">
                Save Changes
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

      <image-cropper v-if="selectedImage" :image="selectedImage" @cropped="imageCropped" />
    </b-card>
  </b-overlay>
</template>

<script>
import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';

import store from '@/store';
import userStoreModule from '../userStoreModule';
import { required, alphaNum, email } from '@validations';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import router from '@/router';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axiosIns from '@/libs/axios';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    vSelect,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
    'b-modal': VBModal,
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'user';

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
  },

  data: function() {
    var userValue = {
      name: null,
      surname: null,
      email: null,
      role: null,
      password: null,
      status: null,
    };

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Passive', value: 'passive' },
    ];

    return {
      userValue: userValue,
      baseURL: store.state.app.baseURL,
      selectedImage: null,
      userData: JSON.parse(localStorage.getItem('userData')),
      required,
      email,
      roleOptions: [
        {
          id: 1,
          name: 'manager',
          permissions: [],
        },
        {
          id: 2,
          name: 'user',
          permissions: [],
        },
      ],
      statusOptions,
      userId: null,
      formShow: false,
    };
  },

  created() {
    this.getUserById();
  },

  methods: {
    getUserById() {
      this.formShow = true;

      if (router.currentRoute.params.id) {
        this.userId = parseInt(router.currentRoute.params.id);

        store
          .dispatch('user/getUserById', { id: router.currentRoute.params.id })
          .then((response) => {
            this.userValue = response.data;

            this.formShow = false;
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            });
          });
      } else {
        this.formShow = false;
      }
    },

    formSubmitted() {
      this.formShow = true;
      this.$refs.userRules.validate().then((success) => {
        if (success) {
          if (router.currentRoute.params.id) {
            store
              .dispatch('user/updateUser', this.userValue)
              .then((response) => {
                if (response.status == 200) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Successful',
                      text: '✔️ User Update Successful',
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  });

                  setTimeout(function() {
                    this.formShow = false;
                    router.push({ name: 'user-list' });
                  }, 100);
                }
              })
              .catch((error) => {
                this.formShow = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                  },
                });
              });
          } else {
            store
              .dispatch('user/userCheck', { email: this.userValue.email })
              .then((response) => {
                if (response.data == 'no') {
                  store
                    .dispatch('user/saveUser', this.userValue)
                    .then((response) => {
                      if (response.status == 200) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Successful',
                            text: '✔️ User Add Successful',
                            icon: 'ThumbsUpIcon',
                            variant: 'success',
                          },
                        });

                        setTimeout(function() {
                          this.formShow = false;
                          router.push({ name: 'user-list' });
                        }, 1000);
                      }
                    })
                    .catch((error) => {
                      this.formShow = false;
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'An error occurred',
                          text: 'Please try again later or contact support.',
                          icon: 'AlertCircleIcon',
                          variant: 'danger',
                        },
                      });
                    });
                } else {
                  this.formShow = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'This email exists',
                      text: 'Please enter another email address.',
                      icon: 'RefreshCcwIcon',
                      variant: 'warning',
                    },
                  });
                }
              })
              .catch((error) => {
                this.formShow = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                  },
                });
              });
          }
        } else {
          this.formShow = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
