var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{staticClass:"mt-1",attrs:{"show":_vm.formShow,"rounded":"sm","no-fade":""}},[_c('b-card',[_c('b-row',{staticClass:"mb-1"},[_c('table',{staticClass:"table table-sm"},[_c('thead',[_c('tr',[(_vm.userId > 0)?_c('th',{attrs:{"scope":"col"}},[_vm._v("User Edit")]):_c('th',{attrs:{"scope":"col"}},[_vm._v("User Add")])])])])]),_c('validation-observer',{ref:"userRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","type":"text"},model:{value:(_vm.userValue.name),callback:function ($$v) {_vm.$set(_vm.userValue, "name", $$v)},expression:"userValue.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Surname","label-for":"surname"}},[_c('validation-provider',{attrs:{"name":"Surname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"surname","type":"text"},model:{value:(_vm.userValue.surname),callback:function ($$v) {_vm.$set(_vm.userValue, "surname", $$v)},expression:"userValue.surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"text","disabled":_vm.userId > 0},model:{value:(_vm.userValue.email),callback:function ($$v) {_vm.$set(_vm.userValue, "email", $$v)},expression:"userValue.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.userValue.id == null)?_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","type":"text"},model:{value:(_vm.userValue.password),callback:function ($$v) {_vm.$set(_vm.userValue, "password", $$v)},expression:"userValue.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1840047599)})],1)],1):_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('b-form-input',{attrs:{"id":"password","type":"text"},model:{value:(_vm.userValue.password),callback:function ($$v) {_vm.$set(_vm.userValue, "password", $$v)},expression:"userValue.password"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('b-form-group',{attrs:{"label":"User Role","label-for":"role"}},[_c('validation-provider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.roleOptions,"reduce":function (val) { return val.name; },"label":"name","clearable":false,"input-id":"role"},model:{value:(_vm.userValue.role),callback:function ($$v) {_vm.$set(_vm.userValue, "role", $$v)},expression:"userValue.role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Status","label-for":"status"}},[_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.statusOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"status"},model:{value:(_vm.userValue.status),callback:function ($$v) {_vm.$set(_vm.userValue, "status", $$v)},expression:"userValue.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[(_vm.userId == null)?_c('b-button',{staticClass:"float-right",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.formSubmitted.apply(null, arguments)}}},[_vm._v(" Save ")]):_c('b-button',{staticClass:"float-right",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.formSubmitted.apply(null, arguments)}}},[_vm._v(" Save Changes ")])],1)],1)],1)],1),(_vm.selectedImage)?_c('image-cropper',{attrs:{"image":_vm.selectedImage},on:{"cropped":_vm.imageCropped}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }